.badge {
  --badge-size: 40px;
  --badge-img-radius: 2px;
  --badge-img-size: 36px;
  display: inline-flex;
  border-radius: 3px;
  width: auto;
  min-width: var(--badge-size);
  height: var(--badge-size);
  line-height: var(--badge-size);
  border: none !important;
  font-size: 13px;
  margin: 0 8px 8px 0 !important;
  opacity: var(--unselected-opacity);

  img,
  .badge-color {
    width: var(--badge-img-size);
    height: var(--badge-img-size);
    border-radius: var(--badge-img-radius);
    margin-right: 4px;
  }
  span {
    font-size: 12px;
  }
}

.frame-type-option {
--unselected-opacity: 1;
--selected-opacity: 0.45;
  &.is-selected > * {
    opacity: var(--selected-opacity);
  }  
}

.badge-color {
  &.black {
    background-color: black;
  }
  &.white {
    background-color: #fff;
    border: 2px solid #333;
  }
  &.none {
    border: 2px solid red;
  }
}
.more-information {
  text-transform: initial;
  font-weight: 300;
  font-size: 11px;
  font-style: italic;
}

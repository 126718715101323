.mt0 {
  margin-top: 0;
}
.mt1-2 {
  margin-top: 0.5em;
}
.mt1 {
  margin-top: 1em;
}
.mt2 {
  margin-top: 2em;
}
.mb0 {
  margin-bottom: 0;
}
.mb1-4 {
  margin-bottom: 0.25em !important;
}
.mb1-2 {
  margin-bottom: 0.5em !important;
}
.mb1 {
  margin-bottom: 1em;
}
.mb2 {
  margin-bottom: 2em;
}
.mb3 {
  margin-bottom: 3em;
}
.mr1-2 {
  margin-right: 0.5em !important;
}
.ml1-2 {
  margin-left: 0.5em !important;
}
.p1-2 {
  padding: 0.5em;
}
.p1 {
  padding: 1em;
}
.pl0 {
  padding-left: 0;
}
.px1 {
  padding-left: 1em;
  padding-right: 1em;
}
.w100 {
  width: 100%;
}

.width-auto {
  width: auto;
}

.list-unstyled {
  list-style-type: none;
  margin: 0;
}

.float-left {
  float: left;
}

.list-inline li {
  float: left;
}

.pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.grid-2 {
  display: block;
  @media all and (min-width: 501px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
  @media all and (max-width: 500px) {
    > * {
      margin-bottom: 15px;
    }
  }
}

.span-2 {
  grid-column: span 2;
}

.display-block {
  display: block;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.is-on-top {
  z-index: 99;
}

.flex-pull-right {
  margin-left: auto;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-muted {
  color: #ccc;
}

.text-white {
  color: #fff;
}

.center-block {
  margin-left: auto !important;
  margin-right: auto !important;
}

.lh0 {
  line-height: 0;
}

.mw-540 {
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
}

.mw-740 {
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
}


.text-small {
  font-size: 12px;
}

.text-med {
  font-size: 14px;
}

.has-white-color {
  color: #fff;
}

.has-dark-bg {
  background-color: #ccc;   
  color: #fff; 
}

.has-border-radius {
  border-radius: 3px;
}
.btn {
  background: #0f0f0f;
  color: #fff;
  padding: 10px 15px;
  margin: 0.5em 0;
  border: none;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  text-align: center;
  &.button-secondary {
    background: #3d3d3d;
  }
  &:hover {
    background: lighten(#0f0f0f, 10%);
  }
  &.is-outline {
    background: transparent;
    &:hover {
      opacity: 0.8;
    }
  }
  &.is-none {
    padding: 0;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.btn-small {
    font-size: 11px;
    padding: 10px 13px;
  }
}

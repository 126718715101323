.container {
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
  padding: 0 15px;
}

.has-sidebar {
  display: grid;
  @media all and (min-width: 750px) {
    grid-template-columns: 1fr 400px;
  }
  grid-gap: 20px;
}

.sidebar {
  padding: 20px;
  background-color: #fafafa;
  border-radius: 3px;
  color: #333;
  text-align: left;
}

/**
Grids
**/
.grid {
  display: grid;
  grid-gap: 10px;
  &.grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.single-image {
  background-color: #f9f9f9;
  border-radius: 3px;
  color: #333;
  padding: 20px;
}

@import 'image-frame';

.single-image-title {
  font-size: 16px;
}

.photo-user {
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-gap: 10px;
  text-align: left;
  align-items: center;
  font-size: 13px;

  img {
    border-radius: 100%;
  }
}

.product-total {
  p {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
  }
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.filters {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: rgba(255, 255, 255, 1);

  button {
    margin-left: auto;
    display: inline-flex;
  }
}

.filter-tags {
  > * {
    font-size: 12px;
    text-transform: initial;
    margin-right: 8px;
    background-color: #f1f1f1;
    border-radius: 3px;
    display: inline-flex;
    height: 36px;
    line-height: 36px;
    padding-left: 12px;
    padding-right: 12px;
    color: #333;
  }
}

.my-list {
  .list-handle {

    padding: 15px;
    width: 100vh;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    @media all and (min-width: 750px) {
      transform: rotate(90deg);
      transform-origin: left top 0;
    }
    @media all and (max-width: 749px) {
      position: fixed;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
      background-color: var(--color-dark);
      padding: 10px 15px;
      border-radius: var(--border-radius) var(--border-radius) 0 0;
    }
  }

  .my-list-content {
    padding: 20px;
    overflow-y: scroll;
    max-height: calc(100vh - 30px);
  }

  position: fixed;
  right: -280px;
  top: 0;
  width: 300px;
  height: 100%;
  background: #333;
  border-left: 1px solid #f1f1f1;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);

  @media all and (max-width: 540px) {
    right: -290px;
  }

  &.is-open {
    right: 0px;
    display: block;
  }

  .list-item {
    font-size: 13px;
    text-align: left;
    position: relative;
    margin-bottom: 1em;
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-gap: 10px;
  }

  .list-item span {
    display: block;
    margin-bottom: 0.25em;
  }

  .total {
    text-transform: uppercase;
    font-weight: 600;
  }

  span.quantity {
    display: inline-flex;
    position: absolute;
    left: -55px;
    top: -5px;
    font-size: 11px;
    width: 14px;
    height: 14px;
    line-height: 14px;
    background-color: #fff;
    color: #333;
    text-align: center;
    justify-content: center;
    border-radius: 100%;
  }

  span.remove {
    top: 0;
    right: 0;
    &:before {
      content: '\e8da';
    }
  }
}

.link {
  &:before {
    content: '\e86a';
  }
}

.single-photo {
  &.is-loading {
    .sidebar {
      filter: blur(0.5px);
    }
  }
}

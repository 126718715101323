:root {
  --color-dark: #333;
  --border-radius: 3px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Feather';
  src: local('Feather'), url(./fonts/feather.woff2) format('woff2');
}

.font-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

body {
  font-size: 1em;
  background-color: var(--color-dark);
}

.web-font {
  display: block;
  font-family: 'feather' !important;
}

.App {
  text-align: center;  
  min-height: 100vh;
  min-height: -webkit-fill-available; // iOS safer fill
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // font-size: calc(10px + 2vmin);
  color: white;
}

.app-header {
}

a {
  color: inherit;
  text-decoration: none;
}

a.link {
  color: #06c;
  text-decoration: underline;
}

.image-grid {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media all and (min-width: 550px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  grid-gap: 10px;
}

.image-grid-item {
  position: relative;
  background-size: cover;
  height: 300px;
  max-width: 300px;
  overflow: hidden;
  border-radius: 0px;
  margin: 0;
  width: 100%;

  @media all and (max-width: 540px) {
    height: 250px;
  }

  &.has-image {
    padding: 15px;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    );
    img {
      object-fit: contain;
    }

    .image-title {
      font-size: 16px;
      text-overflow: ellipsis;

      /* Needed to make it work */
      overflow: hidden;
      white-space: nowrap;
      
    }
  }

  .image-title {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    );
    max-width: 100%;
    width: 100%;
    
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  img {
    width: 100%;
    max-height: 100%;
  }
}

@import 'scss/layout';

img {
  width: 100%;
}

.frame-type-options {
}

.frame-type-option {
  display: inline-block;
  position: relative;
  font-size: 12px;
}

@import 'scss/forms';
@import 'scss/widgets';
@import 'scss/badge';
@import 'scss/atomic';
@import 'scss/buttons';
@import 'scss/modal';
@import 'scss/skeleton';

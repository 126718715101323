.single-image-frame {
  object-fit: contain;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
  box-shadow: 0 15px 20px rgba(105, 78, 78, 0.2);
  --frame-thickness: 4px;
  --outline-thickness: 1px;
  border: var(--frame-thickness) solid transparent;

  &.is-large-preview {
    --frame-thickness: 10px;
    --outline-thickness: 3px;
    width: auto;
    max-width: 100%;
    max-height: 400px;
    border: var(--frame-thickness) solid transparent;
  }
  &.frame-type-white {
    border-color: #fff;
  }
  &.frame-type-black {
    border-color: #333;
  }
  &.frame-type-none {
    border-color: transparent;
    border-width: 0px;
  }
  &.frame-type-oak {
    border-color: #ffaf4b;
    border-image: url('../img/oak.png') 1 repeat;
  }
  &:not(.frame-type-none) {
    outline: var(--outline-thickness) solid rgba(#000, 0.6);
    outline-offset: calc(var(--frame-thickness) * -1);
  }
  &.print-type-framed {
    --outline-thickness: 12px;
    --frame-thickness: 8px;
    outline: var(--outline-thickness) solid rgba(#eeeeee, 1);
    outline-offset: calc((var(--outline-thickness) + var(--frame-thickness) + -2px)  * -1);
    box-shadow: 0 7.5px 10px rgba(105, 78, 78, 0.1);
  }
}

.single-image__label {
  top: 20px;
  right: 60px;
  font-size: 11px;
  background-color: #292929;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 3px 4px;
}
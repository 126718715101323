.skeleton {
  display: block;
  background-color: #d3d3d3;
  &.is-image {
    background: linear-gradient(lightgrey 300px, transparent 0);
  }
}

.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation-name: fade-in;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3 ease-in-out;
  &.is-open {
    opacity: 1;
    visibility: visible;
  }
}

.modal-main {
  position: fixed;
  background: white;
  max-width: 600px;
  width: 90%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  color: #333;
  text-align: left;
}

.modal-content {
  max-height: calc(100vh - 200px);
  height: auto;
  overflow-y: auto;
}

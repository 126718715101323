.widget-title {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 12px;
  text-transform: uppercase;
}

.widget {
  background-color: #fff;
  padding: 15px;
}

.close-widget {
  right: 10px;
  top: 10px;
  display: inline-block;
  width: auto;
  color: #f9f9f9;
}

.help-widget {
  left: 10px;
  top: 10px;
  display: inline-block;
  width: auto;
  color: #f9f9f9;
  font-size: 12px;
}

.toast {
  background-color: #fff;
  padding: 12px 20px;
  display: inline-block;
  position: fixed;
  font-size: 13px;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  color: #333;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4 ease-in-out;

  &.is-open {
    opacity: 1;
    visibility: visible;
  }
}
